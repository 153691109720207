import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {concatMap, merge} from 'rxjs/operators';
import { of  } from 'rxjs';

interface waypoint {
  hint: string,
  distance: string,
  location: string,
  name: string
}

interface step {
  geometry: string,
  distance: string,
  name: string
}
interface steps {
  steps: step[]
}
interface route {
  legs: steps[]
}

interface directions {
  code: string,
  waypoints: waypoint[],
  routes: route[]
}

interface address {
  village: string,
  state_district: string,
  state: string,
  country: string, 
  country_code: string
}

interface autoCompRes {
    place_id: string,
    licence: string,
    osm_Type: string,
    osm_id: string,
    boundingbox: string,
    lat: number,
    lon: number,
    display_name: string,
    class: string,
    type: string,
    importance: string,
    icon: string,
    address: address
}

interface location {
  lat: number,
  lon: number
}
interface lineDetail {
  company: string,
  plspec: string,
  length: number,
  status: string,
  from_fac: string,
  to_fac: string,
  diameter: number, 
  thickness: number,
  maop: number,
  substance: string
}

@Injectable({
  providedIn: 'root'
})
export class DataLookupService {


  constructor(private httpClient: HttpClient) { }
  autoCompleteUrl = 'https://albertaog.ca/geocode/search.php';
  directionsUrl = 'https://routes.albertaog.ca/route/v1/driving/'; 
  plDetailsUrl = 'https://www1.albertaog.ca/line_detail.php';
  plShapeUrl = 'https://www1.albertaog.ca/line_shape.php';

  autoComplete(keyword) {
    return this.httpClient.get<autoCompRes[]>(this.autoCompleteUrl + '?q=' +keyword + '&limit=5&format=json&addressdetails=1');
  }
  directions(from: location, to: location) {
    return this.httpClient.get<directions>(this.directionsUrl + from.lon+','+from.lat+';'+to.lon+','+to.lat+'?overview=false&alternatives=true&steps=true&hints=;');
  }
  pldetail(name: string) {
    return this.httpClient.post<lineDetail>(this.plDetailsUrl, JSON.stringify({'name': name}))
  }
  shape(name: string, geometry: string) {
    return this.httpClient.post<string>(this.plShapeUrl, JSON.stringify({'name': name}), {responseType: 'text' as 'json'}).pipe(concatMap(val => of([val,name, geometry])));
  }
}
