import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapDirective } from './map.directive';



@NgModule({
  declarations: [MapDirective],
  imports: [
    CommonModule
  ],
  exports: [
    MapDirective
  ]
})
export class SharedModule { }
