import { Directive, ElementRef, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {Map} from 'leaflet';
import * as L from 'leaflet';
import 'polyline-encoded';
import 'leaflet-textpath';

declare var require: any

@Directive({
  selector: '[appMap]'
})
export class MapDirective {
  
  @Input() locations: BehaviorSubject<string[]>;
  @Input() geoms: BehaviorSubject<string>;
  @Input() clearMarkers: BehaviorSubject<Boolean>;

  map: Map;
  markersLayer = new L.LayerGroup(); // NOTE: Layer is created here!
  
  constructor(private el: ElementRef) { 
    this.map = new Map(this.el.nativeElement ).setView([53,-116],6);
  }
  onPopupOpen(){
    var tempMarker = this;
    console.log("Doing something with the marker")
    // To remove marker on click of delete button in the popup of marker
    /*$(".marker-delete-button:visible").click(function () {
        this.map.removeLayer(tempMarker);
    });*/
   }
   ngOnInit(){
    //let token = 'pk.eyJ1IjoiamFkYW1zMTYiLCJhIjoiY2tjYWtsbHVqMXc5dzMwcW9ob2ZlYTBsZiJ9.wjkfSz_AoTSbh-WAJQ56yA';
    //let url = 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=';
    let url = 'https://www1.albertaog.ca/osm_tiles/{z}/{x}/{y}.png';
    L.tileLayer(url,{attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>| |<a href="https://www.aer.ca/providing-information/data-and-reports/maps-mapviewers-and-shapefiles">Pipeline Data</a>', maxZoom:18}).addTo(this.map);
    let stop = new Date().getTime();
    stop = Math.floor(stop / 1000.0)-25200;
    let start = stop - 86400;
    
    this.locations.subscribe( obs => {
      console.log(obs);
      if(obs.length > 2){
        if(obs[0] != '0' && obs[1] != '0' && obs[0] != null && obs[1] != null) {
          var numberIcon = L.divIcon({
            className: "awesome-marker",
            iconSize: [35, 45],
            iconAnchor: [17, 42],
            popupAnchor: [1, -32]
                
            });
          console.log("Added marker")
          let mark = L.marker([obs[0], obs[1]], { icon: numberIcon});
          mark.bindPopup("<b>"+obs[2]+"</b>").openPopup();
          this.markersLayer.addLayer(mark);
          this.map.flyTo([obs[0],obs[1]], 10)
        }
      }
      
    });
    this.markersLayer.addTo(this.map)
    this.geoms.subscribe(observ => {
      try {
        console.log(observ)
        let polyline = L.Polyline.fromEncoded(observ[0], 5).addTo(this.map);
        polyline.setText(observ[1], {center: true});
        var polyUtil = require('polyline-encoded');
        /*observ = observ.replace(/\\"/g, '"')
        observ = observ.replace(/\\'/g, "'")
        observ = observ.replace(/(?:\\(.))/g, '$1');*/
        var latlngs = polyUtil.decode(observ,5);
        if(latlngs.length > 2) {
          this.map.flyTo([latlngs[2][0],latlngs[2][1]], 10);
        }
        
        
        
      } catch (err) {
        console.log(err);
      }
      
      console.log("Adding " + observ)
      //L.Polyline.fromEncoded(observ).addTo(this.map);
    })
    this.clearMarkers.subscribe(ob => {
      this.markersLayer.clearLayers();
    })
    //Not doing anything with the geoms layer ATM
    /*L.Routing.control({
  	waypoints: [
    L.latLng(52.4, -113.94),
    L.latLng(52.35, -113.949)
  ]
	}).addTo(this.map);
L.Control.geocoder().addTo(this.map);
*/
   }
   ngAfterViewInit() {
    
    this.map.invalidateSize();
   }
   

}
