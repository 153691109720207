<div class="ui two column stackable grid">
    <div class="column">
        <div class="row">
            <div class="leaflet-container">
                <div id="map" appMap [locations]="locations" [geoms]="geoms" [clearMarkers]="clearMarkers"></div>
            </div>
        </div>
    </div>
    <div class="column">
        <div class="row">
            <form [formGroup]="searchForm" (ngSubmit)="search()" class="ui large form">
                <div class="two fields">
                  <div class="ui field search focus">
                    <label>From Site</label>
                    <div class="ui input">
                      <input formControlName="fromName" class="prompt" placeholder="From" type="text">
                    </div>
                    <div *ngIf="fromACVisible" class="results transition" (ngClass)="{'visible': fromACVisible}" style="display: block !important;">
                      <a *ngFor="let result of fromACResults" class="result" (click)="selectFrom(result.display_name, result.lat, result.lon)"><div class="content"><div class="title">{{result.display_name}}</div></div></a>
                    </div>
                    
                  </div>
                  <div class="ui field search focus">
                    <label>To Site</label>
                    <div class="ui input">
                      <input formControlName="toName" placeholder="To" type="text">
                    </div>
                    <div *ngIf="toACVisible" class="results transition" (ngClass)="{'visible': toACVisible}" style="display: block !important;">
                      <a *ngFor="let result of toACResults" class="result" (click)="selectTo(result.display_name, result.lat, result.lon)"><div class="content"><div class="title">{{result.display_name}}</div></div></a>
                    </div>
                  </div>
                </div>
                <button class="ui submit button" action="submit">Submit</button>
            </form>
    </div>
</div>
<div class="ui stackable grid ">
  <div class="column loading">
    <div class="row">
      <div class="ui loader" [ngClass]="{'active': loading}"></div>
      <div *ngIf="loading" class="ui text loader">Loading</div>
      <div [ngClass]="{'loader': loading, 'active': loading}"></div>
    </div>
  </div>
</div>


<div class="ui container" *ngIf="details" >

  <table class="ui selectable celled table">
    <thead>
      <tr><th>Step</th><th>License - Line</th><th>Company</th><th>From</th><th>To</th><th>Length</th><th>Status</th><th>Diameter</th><th>Substance</th></tr>
    </thead>
    <tr *ngFor="let detail of details">
      <td>{{detail.step}}</td>
      <td>{{detail.liclino}}</td>
      <td>{{detail.company}}</td>
      <td>{{detail.from_fac}}</td>
      <td>{{detail.to_fac}}</td>
      <td>{{detail.length}}</td>
      <td>{{detail.status}}</td>
      <td>{{detail.diameter}}</td>
      <td>{{detail.substance}}</td>
  </tr>
  </table>
</div>
<div >
  

</div>
