import { Component, OnInit } from '@angular/core';
import { DataLookupService } from '../data-lookup.service';
import { BehaviorSubject} from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as L from 'leaflet';


interface address {
  village: string,
  state_district: string,
  state: string,
  country: string, 
  country_code: string
}

interface autoCompRes {
    place_id: string,
    licence: string,
    osm_Type: string,
    osm_id: string,
    boundingbox: string,
    lat: number,
    lon: number,
    display_name: string,
    class: string,
    type: string,
    importance: string,
    icon: string,
    address: address
}
interface location {
  lat: number,
  lon: number
}

interface waypoint {
  hint: string,
  distance: string,
  location: string,
  name: string
}

interface step {
  geometry: string,
  distance: string,
  name: string
}
interface steps {
  steps: step[]
}
interface route {
  legs: steps[]
}

interface directions {
  code: string,
  waypoints: waypoint[],
  routes: route[]
}

interface lineDetails {
  step: number,
  liclino: string,
  company: string,
  plspec: string,
  length: number,
  status: string,
  from_fac: string,
  to_fac: string,
  diameter: number,
  thickness: number,
  maop: number,
  substance: string
}


@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit {

  loading = false;
  locations = new BehaviorSubject([0,0,'']);
  geoms = new BehaviorSubject(['','']);
  clearMarkers = new BehaviorSubject(true);
  internalLocs: number[][] = [];
  internalName: string[] = [];
  fromACResults: autoCompRes[];
  toACResults: autoCompRes[];
  fromACVisible = false;
  toACVisible = false;
  
  fromLoc: location;
  toLoc: location;
  steps: step[];
  details: lineDetails[];

  searchForm = new FormGroup({
    fromName: new FormControl('', [Validators.required]),
    toName: new FormControl('', [Validators.required]),
  });
  constructor(private dataLookup: DataLookupService) { }

  addLocation(lat: number, lon: number, name: string, deviceNo: number) {
    this.internalLocs.push([lat,lon]);
    this.internalName.push(name);
    this.locations.next([lat,lon, name, deviceNo]);
  }
  deleteLocation(index: number) {
    this.internalLocs.splice(index,1);
    this.internalName.splice(index,1)
    this.clearMarkers.next(true);
    var i = 1
    this.internalLocs.forEach(waypoint => {
      waypoint[2] = i
      this.locations.next(waypoint)
      i = i+1
    })
  }

  ngOnInit(): void {
    this.searchForm.get('fromName').valueChanges.subscribe( keyword => {
      if (keyword.length > 2) {
        this.dataLookup.autoComplete(keyword).subscribe( results => {
          this.fromACResults = results;
          this.fromACVisible = true;
          console.log(this.fromACResults);
        });
      }
      
    });
    this.searchForm.get('toName').valueChanges.subscribe( keyword => {
      this.dataLookup.autoComplete(keyword).subscribe( results => {
        this.toACResults = results;
        this.toACVisible = true;
        console.log(this.toACResults);
      })
    });

  }

  selectFrom(value, lat, lon) {
    this.searchForm.get('fromName').setValue(value);
    this.fromLoc = {'lat': lat, 'lon': lon};
    setTimeout(() => {this.fromACVisible = false; },400);
  }
  selectTo(value, lat, lon) {
    this.searchForm.get('toName').setValue(value);
    this.toLoc = {'lat': lat, 'lon': lon};
    setTimeout(() => {this.toACVisible = false; },400);
  }
  search() {
    let fromName = this.searchForm.get('fromName').value;
    let toName = this.searchForm.get('toName').value;
    this.loading = true;
    this.locations.next([this.fromLoc.lat, this.fromLoc.lon, fromName]);
    this.locations.next([this.toLoc.lat, this.toLoc.lon, toName]);
    var i = 0;
    this.details=[];
    this.dataLookup.directions(this.fromLoc, this.toLoc).subscribe(results => {
      this.steps = results.routes[0].legs[0].steps;
      this.steps.forEach(step => {
        console.log(step);
        //this.geoms.next(step.geometry);
        this.dataLookup.shape(step.name, step.geometry).subscribe(plshape => {
          if(plshape[0] == 'Failed') {
            this.geoms.next([plshape[2], plshape[1]]);
          }
          else {
            this.geoms.next([plshape[0], plshape[1]]);
          }
          console.log(plshape);
        });
        this.dataLookup.pldetail(step.name).subscribe(pldetail => {

          this.loading=false;
          i++;
          if (pldetail != null) {
            this.details.push({
              step: i,
              liclino: step.name,
              company: pldetail.company,
              plspec: pldetail.plspec,
              length: pldetail.length,
              status: pldetail.status,
              from_fac: pldetail.from_fac,
              to_fac: pldetail.to_fac,
              diameter: pldetail.diameter,
              thickness: pldetail.thickness,
              maop: pldetail.maop,
              substance: pldetail.substance
            });
            //var geom = (pldetail.geom);
            //this.geoms.next(geom);
          }
          
          console.log(pldetail);
        });
        
      });
    });
  }
  autoComplete($event) {
    console.log($event);
  }
}
